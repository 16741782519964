<template>
  <CFormGroup
    v-bind="{
      append,
      prepend,
      validFeedback,
      invalidFeedback,
      tooltipFeedback,
      description,
      wrapperClasses,
      class: computedClasses,
    }"
  >
    <template #label>
      <slot name="label">
        <label v-if="label" :for="safeId" :class="labelClasses">
          {{ label }}
        </label>
      </slot>
    </template>

    <template #input>
      <select
        v-bind="$attrs"
        v-on="$listeners"
        :id="safeId"
        :class="inputClasses"
        @input="onSelect($event)"
      >
        <option v-if="placeholder" value="" selected disabled hidden>
          {{ placeholder }}
        </option>
        <template v-for="(option, key) in options">
          <optgroup v-if="isGroup(option)" :label="option.label" :key="key">
            <option
              v-for="(goption, gkey) in option.options"
              :value="goption.value"
              v-bind="goption.attrs"
              :disabled="goption.disabled"
              :selected="goption.value === value"
              :data-key="key"
              :key="gkey"
            >
              {{ goption.label || goption.value }}
            </option>
          </optgroup>
          <option
            v-else-if="typeof option === 'object'"
            :value="option.value"
            v-bind="option.attrs"
            :disabled="option.disabled"
            :selected="option.value === value"
            :data-key="key"
            :key="key"
          >
            {{ option.label || option.value }}
          </option>
          <option
            v-else
            :value="option"
            :selected="option === value"
            :data-key="key"
            :key="key"
          >
            {{ option }}
          </option>
        </template>
      </select>
    </template>

    <template v-for="slot in $options.slots" #[slot]>
      <slot :name="slot"></slot>
    </template>
  </CFormGroup>
</template>

<script>
import * as allFormMixins from "@coreui/vue/src/components/form/form-mixins";
const mixins = Object.values(allFormMixins);
import { selectProps as props } from "@coreui/vue/src/components/form/form-props";

export default {
  name: "UiSelect",
  inheritAttrs: false,
  slots: [
    "prepend",
    "prepend-content",
    "append-content",
    "append",
    "label-after-input",
    "valid-feedback",
    "invalid-feedback",
    "description",
  ],
  mixins,
  props,

  data() {
    return {
      state: this.value || undefined,
    };
  },
  computed: {
    customSizeClass() {
      if (this.haveCustomSize && !this.haveWrapper) {
        return `${this.custom ? "custom-select" : "form-control"}-${this.size}`;
      }
      return "";
    },
    inputClass() {
      const standardClass = `form-control${this.plaintext ? "-plaintext" : ""}`;
      return this.custom ? "custom-select" : standardClass;
    },
  },

  methods: {
    onSelect(e) {
      if (this.$attrs.multiple !== undefined) return;
      // const optionIndex = e.target.selectedOptions[0].dataset.key;
      // const option = this.options[optionIndex];
      // const value = option.value || option;
      const value = e.target.value;
      this.state = value;
      this.$emit("update:value", value, e);
    },
    isGroup(option) {
      return typeof option.label === "string" && Array.isArray(option.options);
    },
  },
};
</script>
